<div #storeLocator>
  <!-- Desktop view -->
  <div class="desktop-view" *ngIf="!mobileView">
    <div class="left-side-bar">
      <ng-container *ngTemplateOutlet="sideMenuTemplate"></ng-container>
    </div>

    <div class="scrollable-bar" *ngIf="isCollapsed">
      <div
        [ngClass]="{
          'list-header-search': true,
          'small-search': !locIsSelected,
          'wide-search': locIsSelected
        }"
      >
        <span class="p-input-icon-left">
          <label id="SearchHereLabel" for="SearchBox" class="visuallyhidden"
            >This is a Search box</label
          >
          <input
            id="SearchBox"
            aria-labelledby="SearchHereLabel"
            type="text"
            pInputText
            placeholder="Search by zip code, city, state"
            [(ngModel)]="searchInput"
            (keyup)="handleInputKey($event)"
            #autocomplete
          />
          <i class="pi pi-search"></i>
        </span>
        <label>
          <label id="Choosefromdropdown1" for="dropdown" class="visuallyhidden"
            >This is a dropdown</label
          >
          <p-dropdown
            ariaLabel="dropdown"
            aria-label="dropdown"
            id="dropdown"
            aria-labelledby="Choosefromdropdown1"
            type="text"
            *ngIf="!locIsSelected"
            [options]="sortOptions"
            [(ngModel)]="selSort"
            optionLabel="name"
            (onChange)="sortChanged($event.value.code)"
          >
            <ng-template pTemplate="selectedItem">
              <div>{{ "Sort by: " + selSort?.name }}</div>
            </ng-template>
          </p-dropdown></label
        >
      </div>

      <router-outlet></router-outlet>
    </div>

    <div class="expanded-single-location" *ngIf="locIsSelected && !isCollapsed">
      <ng-container
        *ngTemplateOutlet="
          expandedLocTemplate;
          context: { $implicit: selectedLoc }
        "
      ></ng-container>
    </div>

    <div #mapPanel class="map-panel">
      <div
        (click)="onClickEnlarge($event)"
        class="black-enlarge-image"
        [ngClass]="{
          hidden:
            (!locIsSelected && isCollapsed) || (locIsSelected && !isCollapsed)
        }"
      >
        <img
          class="white-double-arrow"
          alt="Enlarge Arrow"
          src="../assets/white-double-arrow.png"
        />
      </div>
      <p-gmap
        [options]="mapOptions"
        [overlays]="poiList"
        [style]="{ width: '100%', height: '100%' }"
        (onMapReady)="mapReady($event)"
        (onMapDragEnd)="mapDragged($event)"
        (onZoomChanged)="zoomChanged($event)"
      >
      </p-gmap>
    </div>
    <div
      (click)="onClickReduce($event)"
      class="black-reduce-image"
      *ngIf="locIsSelected && !isCollapsed"
    >
      <img
        class="white-double-arrow"
        alt=""
        src="../assets/close_fullscreen_arrow.png"
      />
    </div>
  </div>
  <!-- Mobile view -->
  <div class="mobile-view" *ngIf="mobileView">
    <div class="scrollable-bar">
      <div
        class="small-search"
        [ngClass]="{
          'list-header-search': true,
          'small-search': !locIsSelected,
          'wide-search': locIsSelected
        }"
      >
        <span class="p-input-icon-left">
          <label id="label" for="SearchBox2" class="visuallyhidden"
            >This is a Search box</label
          >
          <input
            id="SearchBox2"
            aria-labelledby="label"
            type="text"
            pInputText
            placeholder="Search by zip code, city, state..."
            [(ngModel)]="searchInput"
            id="searchTextField"
            #autocomplete
          />
          <i class="pi pi-search"></i>
        </span>
        <label>
          <label id="Choosefromdropdown" for="dropdown" class="visuallyhidden"
            >This is a dropdown</label
          >
          <p-dropdown
            aria-label="dropdown trigger"
            id="dropdown"
            aria-labelledby="Choosefromdropdown"
            type="text"
            *ngIf="!locIsSelected"
            [options]="sortOptions"
            [(ngModel)]="selSort"
            optionLabel="name"
            (onChange)="sortChanged($event.value.code)"
          >
            <ng-template pTemplate="selectedItem">
              <div>{{ "Sort by: " + selSort?.name }}</div>
            </ng-template>
          </p-dropdown></label
        >
      </div>
      <router-outlet></router-outlet>
    </div>
    <!-- Mobile menu -->
    <div class="mobile-menu-bar" *ngIf="locIsSelected">
      <ng-container
        *ngTemplateOutlet="
          mobileMenuTemplate;
          context: {
            $implicit: selectedLoc,
            rowIndex: i
          }
        "
      ></ng-container>
    </div>
  </div>
</div>
